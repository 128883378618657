/*
 * find the dealers
 */

var Fragments = require('../modules/fragments.js');

var Search = {


    init: function() {

        var searchAutoEl = document.getElementById('search-auto');
        var listingsEl = document.getElementById("search-listing");
        var productBtn = document.getElementById("btn--search-product");
        var siteBtn = document.getElementById("btn--search-site");
        var searchField = document.getElementById('search');
        var _dataProducts = _search.products;
        var _dataPages = _search.pages;
        var _results = [];
        var _type = "product";
        var _searchTerm = "";




        document.getElementById("close-search").addEventListener('click', function(e) {
            e.preventDefault();

            document.querySelector("body").classList.remove("search-active");
        });

        document.getElementById("open-search").addEventListener('click', function(e) {
            e.preventDefault();
            searchField.focus();

            document.querySelector("body").classList.add("search-active");
        });

        document.getElementById("open-search--mobile").addEventListener('click', function(e) {
            e.preventDefault();
            searchField.focus();

            document.querySelector("body").classList.add("search-active");
        });


        productBtn.addEventListener('click', function(e) {  
            e.preventDefault();

            _type = "product";
            siteBtn.classList.add("btn--ghost");
            this.classList.remove("btn--ghost");
            searchField.value = "";
            searchAutoEl.innerHTML = "";
            searchField.setAttribute("placeholder", this.innerHTML);
            listingsEl.querySelector(".info").innerHTML = "";
            listingsEl.querySelector(".list").innerHTML = "";
        });

        siteBtn.addEventListener('click', function(e) {  
            e.preventDefault();

            _type = "site";
            productBtn.classList.add("btn--ghost");
            this.classList.remove("btn--ghost");
            searchField.value = "";
            searchAutoEl.innerHTML = "";
            searchField.setAttribute("placeholder", this.innerHTML);
            listingsEl.querySelector(".info").innerHTML = "";
            listingsEl.querySelector(".list").innerHTML = "";
        });



       
        var addProductsToAutoComplete = function() {

            var list = Fragments.CreateFragment();

            searchAutoEl.innerHTML = "";

            var i = 0;

            _results.map(function(el) {

                if (i++ < 12) {
                   
                   list.addLink(el['link'], el['series'] + " - " + el['name']);
                }
            });

            list.addToDom('search-auto').show();
        };


        var addPagesToAutoComplete = function() {

            var list = Fragments.CreateFragment();

            searchAutoEl.innerHTML = "";

            var i = 0;

            _results.map(function(el) {

                if (i++ < 12) {
                   
                   list.addLink(el['link'], el['name']);
                }
            });

            list.addToDom('search-auto').show();
        };


        var addProductsToList = function() {

            var list = Fragments.CreateFragment();

            searchAutoEl.innerHTML = "";

            var i = 0;

            _results.map(function(el) {
                   
                list.addLink(el['link'], el['series'] + "<span>" + el['name'] + "</span>", "link--product");
            });

            list.addToDom('list').show();
        };

        var addPagesToList = function() {

            var list = Fragments.CreateFragment();

            searchAutoEl.innerHTML = "";

            var i = 0;

            _results.map(function(el) {
                   
                list.addLink(el['link'], el['name'] + "<span>Visit Page</span>", "link--product link--page");
            });

            list.addToDom('list').show();
        };


        searchField.addEventListener('keyup', function(e) { 

            _searchTerm = e.target.value;
            searchAutoEl.classList.remove("element-hidden");
            listingsEl.querySelector(".info").innerHTML = "";
            listingsEl.querySelector(".list").innerHTML = "";

            _results = [];

            if (_type == "product") {

                if(this.value.length > 1) {

                    _results = _dataProducts.filter(function(el) {

                        if (el.name.toLowerCase().indexOf(_searchTerm.toLowerCase()) != -1 ||
                            el.series.toLowerCase().indexOf(_searchTerm.toLowerCase()) != -1) {
                            return true;
                        }
                    });  
                }
                addProductsToAutoComplete();
            }

            else {

                if(this.value.length > 1) {

                    _results = _dataPages.filter(function(el) {

                        if (el.name.toLowerCase().indexOf(_searchTerm.toLowerCase()) != -1 ||
                            el.content.toLowerCase().indexOf(_searchTerm.toLowerCase()) != -1) {
                            return true;
                        }
                    });  
                }
                addPagesToAutoComplete();
            }

            if (e.keyCode === 13) {
               document.getElementById('submit--search').click();
            }
            if (e.keyCode === 40) { 
               //searchAutoEl.focus();
               searchAutoEl.querySelector("a:nth-child(1)").focus();
            }

        });

        searchAutoEl.addEventListener('keyup', function(e) {

            if (e.keyCode === 38 && (this.scrollTop == 0 || !document.activeElement.previousSibling)) {
               searchField.focus();
            }
            if (e.keyCode === 40) { 
               document.activeElement.nextSibling.focus();
            }
            if (e.keyCode === 38) { 
               document.activeElement.previousSibling.focus();
            }
         });


        document.getElementById('submit--search').addEventListener('click', function(e) { 

            var _productText = listingsEl.getAttribute("data-product");

            listingsEl.querySelector(".info").innerHTML = "";
            listingsEl.querySelector(".list").innerHTML = "";

            searchAutoEl.innerHTML = "";
            searchAutoEl.classList.add("element-hidden");

            if (_type == "product" && _searchTerm) {
                
                listingsEl.querySelector(".info").innerHTML = _productText.replace("X", _results.length).replace("Y", _searchTerm);
                addProductsToList();
            }

            if (_type == "site" && _searchTerm) {
                listingsEl.querySelector(".info").innerHTML = _productText.replace("X", _results.length).replace("Y", _searchTerm);
                addPagesToList();
            }

        });


    }
}

module.exports = Search;
