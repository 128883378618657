module.exports={
  "_from": "ejs@^3.0.1",
  "_id": "ejs@3.1.9",
  "_inBundle": false,
  "_integrity": "sha512-rC+QVNMJWv+MtPgkt0y+0rVEIdbtxVADApW9JXrUVlzHetgcyczP/E7DJmWJ4fJCZF2cPcBk0laWO9ZHMG3DmQ==",
  "_location": "/ejs",
  "_phantomChildren": {},
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "ejs@^3.0.1",
    "name": "ejs",
    "escapedName": "ejs",
    "rawSpec": "^3.0.1",
    "saveSpec": null,
    "fetchSpec": "^3.0.1"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/ejs/-/ejs-3.1.9.tgz",
  "_shasum": "03c9e8777fe12686a9effcef22303ca3d8eeb361",
  "_spec": "ejs@^3.0.1",
  "_where": "/Users/avega/Local Sites/mt-montigo/app/public/drive/themes/montigo",
  "author": {
    "name": "Matthew Eernisse",
    "email": "mde@fleegix.org",
    "url": "http://fleegix.org"
  },
  "bin": {
    "ejs": "bin/cli.js"
  },
  "bugs": {
    "url": "https://github.com/mde/ejs/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "jake": "^10.8.5"
  },
  "deprecated": false,
  "description": "Embedded JavaScript templates",
  "devDependencies": {
    "browserify": "^16.5.1",
    "eslint": "^6.8.0",
    "git-directory-deploy": "^1.5.1",
    "jsdoc": "^4.0.2",
    "lru-cache": "^4.0.1",
    "mocha": "^10.2.0",
    "uglify-js": "^3.3.16"
  },
  "engines": {
    "node": ">=0.10.0"
  },
  "homepage": "https://github.com/mde/ejs",
  "jsdelivr": "ejs.min.js",
  "keywords": [
    "template",
    "engine",
    "ejs"
  ],
  "license": "Apache-2.0",
  "main": "./lib/ejs.js",
  "name": "ejs",
  "repository": {
    "type": "git",
    "url": "git://github.com/mde/ejs.git"
  },
  "scripts": {
    "test": "mocha -u tdd"
  },
  "unpkg": "ejs.min.js",
  "version": "3.1.9"
}
