/*
*/

var MobileFilter = {

	

	init: function() {

		

        var $filters = $(".flilter__elem");
        
        $(".flilter__elem h3").on("click", function(e) {
            e.preventDefault();

            //$filters.removeClass("show");
            $(this).parent(".flilter__elem").toggleClass("show");
        });

        $(".flilter__elem").find("a").on("click", function(e) { 
            e.preventDefault();
        });

            
	},
}

module.exports = MobileFilter;
