/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 *
 * ======================================================================== */

if (!Array.prototype.find) {
Array.prototype.find = function(predicate) {
    if (this == null) {
        throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
        value = list[i];
        if (predicate.call(thisArg, value, i, list)) {
            return value;
        }
    }
    return undefined;
};
}


window.$ = window.jQuery;

// Define Methods/Functions outside the router for reusability
var Broadcaster             = require('./modules/broadcaster.js'),
    Scroller                = require('./modules/ascroller.js'),
    FadeInBlocks            = require('./modules/fadeInBlocks.js'),
    Parallax                = require('./modules/parallax.js'),

    MobileNav               = require('./modules/mobileNav.js'),
	Debounce                = require('./modules/debounce.js'),
	Collapser               = require('./modules/collapser.js'),
	CtaPopup                = require('./modules/cta-popup.js'),
    ImagesLoaded            = require('./modules/imagesLoaded.js');
    LoadImagesDeferred      = require('./modules/loadImagesDeferred.js'); // deferred loading
    Search                  = require('./modules/search.js');
    maskInputController     = require('./modules/jqueryMaskedInput');



/*
 * Use this variable to set up the common and page specific functions. If you
 * rename this variable, you will also need to rename the namespace below.
 */
var Router = {
    // All pages
    common: {

        init: function() {
            maskInputController.init();

            /*
			 * Javascript to be fired on every page
             * These can be deleted or refactored to the page where it is used
			 */

            ScrollApp = new Scroller();
            ScrollApp.subscribe(new FadeInBlocks());
            ScrollApp.subscribe(new Parallax());


            // Initialize common controllers
            if ($('.js-slider').length) {
                var Sliders = require('./modules/slick.js');
                Sliders.init('.js-slider');
            }
            MobileNav.init('.js-navigation-toggle');
            ImagesLoaded.init('.js-loaded');
            LoadImagesDeferred.init();
			Collapser.init('.js-collapser-toggle');
            CtaPopup.init(MobileNav);
            Search.init();

            var categories_dropdown = function(){
                var dropdown = document.getElementById("cat");
                function onCatChange() {
                    if ( dropdown.options[dropdown.selectedIndex].value !='-1' ) {
                        if(dropdown.options[dropdown.selectedIndex].value == ''){
                            location.href = "/blog/";
                        }else{
                            location.href = "/blog/category/"+dropdown.options[dropdown.selectedIndex].value+"/";
                        }
                    }else{
                        location.href = "/blog/";
                    }
                }
                if(dropdown){
                    dropdown.onchange = onCatChange;
                }
			}
			categories_dropdown();
        },

        // Finalize will get fired after all js in common.init and page specific js
        finalize: function() {
            ScrollApp.finalize();

            var Reps = require('./modules/repAutocomplete.js');
            Reps.init("autocomplete2", "results2");

            if(navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > 0){
                $(".popup--inspiration").find(".flex").css('display', 'block');
            }
        }
    },

    home: {
        init: function() {

			// Masthead scroll down link
			var ScrollDown = require('./modules/scrollDown.js');
			ScrollDown.init('#scroll-to-content', '#page-masthead', true);


            RotateCircle = require('./modules/rotateCircle.js');
            ScrollApp.subscribe(new RotateCircle());
        }
    },

    page_id_88: {
        init: function() {

			// Init checked prop set up
			// First .flilter__elem checkbox is set cheacked value to true
			$('.flilter__elem').each(function() {
				if($(this).index() != 0) {
					$('input[type="checkbox"]', this).prop('checked', false);
				} else {
					$('input[type="checkbox"]', this).prop('checked', true);
				}
			});

			// When user clicked checkbox, label will be added a class
			// Also, checkbox prop will be set true or false (toggle)
			$('.flilter__elem input[type="checkbox"]').on("click", function(e) {
				e.preventDefault();

				var idx = $(this).parent().parent().index();

				$('.flilter__elem').each(function() {
					if($(this).index() != idx) {
						$('label', this).removeClass('is-selected');
						$('input[type="checkbox"]', this).prop('checked', false);
					}
				});

				if(!$(this).siblings('label').hasClass('is-selected')) {
					$(this).siblings('label').addClass('is-selected');
					$(this).prop('checked', true);
				} else {
					$(this).siblings('label').removeClass('is-selected');
					$(this).prop('checked', false);
				}
			});

			$('.flilter__elem .series-filter-description-toggle').on("click", function(e) {
				$(this).prev().toggleClass('is-opened');
				$(this).toggleClass('is-opened');
			});


			// Above setting should before runs FilterProducts.init
			// Because checked props does not work in filterSeriesProducts.js
			// Always checked prop shows true.
			var FilterProducts = require('./modules/filterSeriesProducts');
            FilterProducts.init({});


			// Selectbox for mobile/tablet user
			if(document.getElementById("series-for-mobile") !== null) {
				var productFilterDropdown = document.getElementById("series-for-mobile");

				function onSeriesChange() {
					if ( productFilterDropdown.options[productFilterDropdown.selectedIndex].value == 0 ) {
						$('.flilter__elem').each(function() {
							$('.filter-row', this).removeClass('is-opened');
							$('label', this).removeClass('is-selected');
							$('input[type="checkbox"]', this).prop('checked', false);
							FilterProducts.getAllProducts();
						});
					} else {
						var selectIdx = parseInt(productFilterDropdown.options[productFilterDropdown.selectedIndex].value) - 1;

						$('.flilter__elem').each(function() {
							$('.filter-row', this).removeClass('is-opened');
							$('label', this).removeClass('is-selected');
							$('input[type="checkbox"]', this).prop('checked', false);
						});

						$('.flilter__elem').eq(selectIdx).find('input[type="checkbox"]').click();
						$('.flilter__elem').eq(selectIdx).find('.filter-row').addClass('is-opened');
		            }
				}

				productFilterDropdown.onchange = onSeriesChange;

				// Init for mobile
				productFilterDropdown.selectedIndex = 1;
				$('.flilter__elem').eq(0).find('.filter-row').addClass('is-opened');
			}

            //var MobileFilter = require('./modules/mobileFilter.js');
            // MobileFilter.init();

        }
    },

    page_id_90: {
        init: function() {

			var FilterProducts = require('./modules/filterProducts');
			//FilterProducts.init({paging: 9, initAmount: 8, itemWidth: "one-third"});
            FilterProducts.init({paging: 9, initAmount: 9, itemWidth: "one-third"});

            $("#skip-filters").on("click", function(e) {
                e.preventDefault();

               $('body, html').animate({ scrollTop: $(".grid--products").offset().top -60 }, 300);
            });

            var MobileFilter = require('./modules/mobileFilter.js');
            MobileFilter.init();

        }
    },

    page_id_92: {
        init: function() {

            var FilterInspiration = require('./modules/filterInspiration');
            FilterInspiration.init(ScrollApp);

            $("#skip-filters").on("click", function(e) {
                e.preventDefault();

               $('body, html').animate({ scrollTop: $(".grid--inspiration").offset().top -60 }, 300);
            });

            var MobileFilter = require('./modules/mobileFilter.js');
            MobileFilter.init();

        }
    },

    page_id_316: {
        init: function(){

            var Dealers = require('./modules/getDealers.js');
            Dealers.init();

            $("#postal").focus();
        }
    },

    page_id_416: {
        init: function(){

            var Reps = require('./modules/repAutocomplete.js');
            Reps.init("autocomplete", "results");

            $("#autocomplete").focus();
        }
    },

	template_contact: {
		init: function(){

			//var Maps      = require('./modules/maps.js'),
			var  FormFocus = require('./modules/formFocus.js');

			//Maps.init('.js-map');
			FormFocus.init();
		}
	}
};

/*
 * The routing fires all common scripts, followed by the page specific scripts.
 * Add additional events for more control over timing e.g. a finalize event
*/
var UTIL = {

    fire: function(func, funcname, args) {
        var namespace = Router;
        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
            namespace[func][funcname](args);
        }
    },
    loadEvents: function() {
        UTIL.fire('common');

        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
        });

        UTIL.fire('common', 'finalize');
    }

};

$(document).ready(UTIL.loadEvents);
