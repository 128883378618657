/*
 *
 * Calculate distance between two locations
 */

var CalcDistance = {

    //#######################   HELPER FUNCTIONS
    // caluclate distance between 2 points AS THE CROW FLIES
    between2Points : function calcDistance(lat1, lon1, lat2, lon2)  {

          var R = 6371; // km
          var dLat = CalcDistance.toRad(lat2-lat1);
          var dLon = CalcDistance.toRad(lon2-lon1);
          var lat1 = CalcDistance.toRad(lat1);
          var lat2 = CalcDistance.toRad(lat2);

          var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
          var d = R * c;
          return d;
    },

    // Converts numeric degrees to radians
    toRad: function (Value) { return Value * Math.PI / 180; }

};

module.exports = CalcDistance;
