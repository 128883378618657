/*
 * Collapser: to expand and collapse target elements like an accordion
 *
 * Author: Sydney Hake
 * Date: April 11 2017
 *
 * Usage:
 * Specifying data-parent will group the collapse
 * elements appropriately into an accordion, and will
 * hide any other previously open elements in that group
 *
 * <i class="icon js-collapser-toggle" data-parent="#mobile-menu" data-target="#target"></i>
 * <div class="is-collapsed" id="target" aria-expanded="false"></div>
**/

var Collapser = {

	config: {
		expandClass       : 'is-expanded',
		targetExpandClass : 'target-expanded',
	},

	expand: function(target, parent) {

		$(parent).find('.' + Collapser.config.expandClass).removeClass(Collapser.config.expandClass);
		$(target).addClass(Collapser.config.expandClass);
		$(target).attr('aria-expanded', 'true');

	},

	collapse: function(target, parent) {

		$(target).removeClass(Collapser.config.expandClass);
		$(target).attr('aria-expanded', 'false');
	},

	init: function(className) {

		$(className).on('click', function(){

			var target = $(this).attr('data-target'),
				parent = $(this).attr('data-parent');

			if ( $(target).hasClass(Collapser.config.expandClass) ) {

				$(this).removeClass(Collapser.config.targetExpandClass);
				Collapser.collapse(target, parent);

			} else {

				$(this).addClass(Collapser.config.targetExpandClass);
				Collapser.expand(target, parent);
			}
 		});
	},
}

module.exports = Collapser;
