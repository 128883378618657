 var   Debounce    = require('./debounce.js');

var aScroller = function () {

    var self = this;

    this.vars = {

        scrollTop: document.body.scrollTop || document.documentElement.scrollTop,
        windowHeight: window.innerHeight,
        lastScrollTop: 0,
        scrollDirection: "down"
    };

    // functions array of the observer
    this.arrHandlers = [];

    //#### subscribe to the scroll event
    this.subscribe = function (fn) {
        self.arrHandlers.push(fn);
    };

    this.unsubscribe = function (fn) {

        self.arrHandlers = self.arrHandlers.filter(function (item) {
            if (item !== fn) {
                return item;
            }
        });
    }; 

    this.callScrollListener = function () {

        // on resize or on initalize reset
        self.vars.windowHeight = window.innerHeight;

        // notify the observer on page load or when resized
        self.fire(true);

        window.removeEventListener("scroll", self.scrollFunction, false);

        window.addEventListener("scroll", self.scrollFunction, false);
    };

    this.scrollFunction = function () {

        self.vars.scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

        if (self.vars.scrollTop > self.vars.lastScrollTop) {
            self.vars.scrollDirection = "down";
        } else {
            self.vars.scrollDirection = "up";
        }

        self.vars.lastScrollTop = self.vars.scrollTop;

        // notify the observer on scroll
        self.fire(false);
    };

    this.fire = function (reset) {

        if (self.arrHandlers instanceof Array) {

            self.arrHandlers.forEach(function (item) {

                try {
                    item.init.call(item, self.vars, reset);
                } catch (e) {}
            });
        }
    };

    // when all the observer are set (global and page specific)
    this.finalize = function () {

        if (document.body.offsetWidth > 1024) {

            $(window).ready(function () {

                try {

                    self.callScrollListener();

                    window.addEventListener("orientationchange", self.myDebouncedFunc);

                    window.onresize = self.myDebouncedFunc;

                } catch (e) {}
            });
        }
    };

    this.myDebouncedFunc = Debounce(function () {
        self.callScrollListener();
    }, 200);
}

module.exports = aScroller;
