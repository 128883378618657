/*
 * load the good quality image after dom is loaded
 *
 */


var LoadImagesDeferred = {


    init: function() {

       
        $(window).on('pageshow', function() {
        
            if (document.body.offsetWidth > 640) {

                $(".js-replace").each(function() {

                    var image = new Image();
                    var $img = $(this).find(".replace");
                    var _self = this;

                    image.src = $img.attr("data-src");

                    image.onload = function() {

                        setTimeout(function() {

                            $img.attr("srcset", $img.attr("data-src"));
                            _self.classList.remove("js-replace");

                        }, 50);
                    }; 
                });
            }
        });

    }
}

module.exports = LoadImagesDeferred;
