/*
 * Module for initializing slick slider
 *
 */

 
// var slick = require('slick-carousel');

var Sliders = {

    setParams: function(options, slider) {
        
        var defaults =  {
            infinite: true,
            fade: true,
            dots: false,
            arrows: true,
            autoplay: false,
            speed: 500,
            pauseOnHover: false,
            pauseOnFocus: false
        };

        return $.extend({}, defaults, options);
    },

    init: function(sliderClass, opts) {

        var $slider = $(sliderClass);

        $slider.on('init', function(slick){

            $slider.addClass("js-in");

        });

        $slider.slick(Sliders.setParams(opts, $slider));

        $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){

            $(".counter-swap").html(nextSlide + 1);

        });

        
    }
}

module.exports = Sliders;
