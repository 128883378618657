/*
 * Module for filtering of products and professional tools
 */



var Fragments = require('../modules/fragments.js');


var FilterProducts = {

    config: {
        filter : ["cat[]"],
        filtered : { "cat" : [] },
        products : json_products,
        subset : json_products,
        subsetAppend : null,
        page: 1,
        paging: 8,
        initStart: 0,
        initAmount: 10,
        $load : $("#load"),
        $body : $("body"),
        $next : $("#next-inspiration"),
        $prev : $("#prev-inspiration"),
        $popup: $("#popup-inspiration"),
        _con : document.getElementById("inspiration-content")
    },
    external: {},

    getFilters: function() {


        $("#reset-filter").on("click", function(e) {
            e.preventDefault();

            $('[type="checkbox"]').prop('checked', false);

            FilterProducts.config.page = 1;
            FilterProducts.config.$load.removeClass("element-hidden");

            Object.keys(FilterProducts.config.filtered).forEach(function(el) {

               FilterProducts.config.filtered[el] = [];
            });

            FilterProducts.getSubset();

             $('body, html').animate({ scrollTop: $(".grid--inspiration").offset().top }, 300);

        });

        $("#form--filter").on("submit", function(e) {

            e.preventDefault();

            FilterProducts.config.page = 1;
            FilterProducts.config.$load.removeClass("element-hidden");

            Object.keys(FilterProducts.config.filtered).forEach(function(el) {

               FilterProducts.config.filtered[el] = [];
            });

            var result = $(this).serializeArray();

            // loop through the checkboxes and add the values to the filtered var
            result.map(function(el) {

                if(FilterProducts.config.filter.indexOf(el.name) > -1 ) {

                    FilterProducts.config.filtered[el.name.substring(0,el.name.length-2)].push(el.value);
                }
            });

            FilterProducts.getSubset();

            $('body, html').animate({ scrollTop: $(".grid--inspiration").offset().top }, 300);
        });

    },

    getSubset : function () {

        FilterProducts.config.subset = FilterProducts.config.products.filter(function(el) {

            return Object.keys(FilterProducts.config.filtered).every(function(name) {

                // if the filter is set
                if (FilterProducts.config.filtered[name].length) {

                    var inCategory = false;

                    if (!el[name]) return false;
                    // loop the categories of the products
                    el[name].forEach(function(productValue) {

                        // check if the categories are matching with the checked ones
                        if (FilterProducts.config.filtered[name].indexOf(productValue) > -1) {
                            inCategory = true;
                        }
                    });

                    if (!inCategory) return false;
                }

                return true;
            });

        });


        var start = FilterProducts.config.initStart,
            amount   = FilterProducts.config.initAmount;

        if (start+amount >= FilterProducts.config.subset.length) {

            amount = FilterProducts.config.subset.length - start;
            FilterProducts.config.$load.addClass("element-hidden");
        }

        if (FilterProducts.config.subset.length == 0) {

            document.getElementById("filter-con").innerHTML = "<div class='container'><strong>Looks like you want something custom, contact a rep near you. Or try searching again with fewer choices.</strong></div>";
            return;
        }

        FilterProducts.getPaginationSubsetAndAddToDOM(start, amount, true);
    },

    setUpLoadMore : function() {

        FilterProducts.config.$load.on("click", function(e) {

            e.preventDefault();

            var start = FilterProducts.config.initStart,
                amount   = FilterProducts.config.initAmount;

            if (FilterProducts.config.page == 1) {

                start = FilterProducts.config.initAmount;
                amount   = FilterProducts.config.paging;
            }
            if (FilterProducts.config.page > 1) {

                start = FilterProducts.config.initAmount + ((FilterProducts.config.page-1) * FilterProducts.config.paging);
                amount   = FilterProducts.config.paging;
            }

            if (start+amount >= FilterProducts.config.subset.length) {

                amount = FilterProducts.config.subset.length - start;
                this.classList.add("element-hidden");
            }

            FilterProducts.config.page++

            FilterProducts.getPaginationSubsetAndAddToDOM(start, amount, false);
        });
    },

    getPaginationSubsetAndAddToDOM : function(start, amount, init) {

        FilterProducts.config.subsetAppend = FilterProducts.config.subset.filter(function(el, id) {

            if (id >= start && id < start+amount) return true;
        });

        FilterProducts.addToDom(init);
    },

    addToDom : function(init) {

        var itemLeft = Fragments.CreateFragment();
        var itemRight = Fragments.CreateFragment();

        FilterProducts.config.subsetAppend.map(function(obj, i) {

            var gridItemInner = Fragments.CreateFragment();
            var productLinkInner = Fragments.CreateFragment();
            var productImageBlock = Fragments.CreateFragment();


            if (obj.imgType == 'big') {

                productImageBlock.addImage(obj.imgS, false, obj.imgS+" 800w, "+obj.img+" 1000w");
            }
            else {
                productImageBlock.addImage(obj.img);
            }


            productImageBlock
                .addTag("span", '<i class="icon-fire"></i> More Information', "view");

            productLinkInner
                .appendFragment("span", productImageBlock, "image-hover")
                .addTag("h3", obj.name);

            // gridItemInner
            //    .addLink("#" + obj.ID, productLinkInner, "inspiration-link type-" + obj.imgType);

            if (i % 2 == 0) {

                gridItemInner
                    .addLink("#" + obj.ID, productLinkInner, "inspiration-link js-para type-" + obj.imgType);

                itemLeft.appendFragment(null, gridItemInner);
            }
            else {

                gridItemInner
                    .addLink("#" + obj.ID, productLinkInner, "inspiration-link type-" + obj.imgType);

                itemRight.appendFragment(null, gridItemInner);
            }

        });


        if (init) {

            document.getElementById("left-con").innerHTML = "";
            document.getElementById("right-con").innerHTML = "";
        }


        // do only one repaint/adding to dom
        itemLeft
            .addToDom("left-con") // id of the dom element
            .show(); // can be used to add loading transition

        itemRight
            .addToDom("right-con") // id of the dom element
            .show(); // can be used to add loading transition


        if (document.body.offsetWidth > 1024) {

            FilterProducts.external.ScrollApp.callScrollListener();
        }



        // <a href="#{{product.ID}}" class="inspiration-link type-{{product.imgType}}">
        //     <span class="image-hover">
        //         <img src="{{product.img}}" alt="{{product.name}}" />
        //         <span class="view">
        //             <i class="icon-fire"></i> More Information
        //         </span>
        //     </span>
        //     <h3>{{product.name}}</h3>
        // </a>
    },

    openPopup : function(id) {

        var curObj = FilterProducts.config.products.find(function(obj) { return obj.ID == id});

        if (!curObj) { console.log("error: no obj found matching ID"); return; }

        if (curObj.next) {

            FilterProducts.config.$next.removeClass("element-hidden");
            FilterProducts.config.$next.attr("href", "#" + curObj.next);

        }
        else {
            FilterProducts.config.$next.addClass("element-hidden");
        }
        if (curObj.prev) {

            FilterProducts.config.$prev.removeClass("element-hidden");
            FilterProducts.config.$prev.attr("href", "#" + curObj.prev);
        }
        else {
            FilterProducts.config.$prev.addClass("element-hidden");
        }

        FilterProducts.config.$body.addClass("inspiration-active");

        FilterProducts.createPopupContent(curObj);


    },

    createPopupContent : function(obj) {

        FilterProducts.config._con.innerHTML = "";
        FilterProducts.config._con.classList.remove("js-in");

        if (obj.bg) {

            FilterProducts.config.$popup.removeClass("js-in");
            var image = new Image();
            image.src = obj.bg;

            image.onload = function() {

                setTimeout(function() {

                    FilterProducts.config.$popup.find(".bg--image").css("background-image", "url(" + obj.bg + ")");
                    FilterProducts.config.$popup.addClass("js-in");
                }, 200);
            }
        }
        else {
            FilterProducts.config.$popup.find(".bg--image").css("background-image", "none");
            FilterProducts.config.$popup.addClass("js-in");
        }

        var contentInner = Fragments.CreateFragment();

        contentInner.addTag("h2", obj.title);

        if (obj.series.length) {
            contentInner.addTag("h3", obj.series[0]);
        }

        contentInner.addTag("div", obj.content, "margin-bottom--small");

        if (obj.link) {
            contentInner.addLink(obj.link, "view product", "btn");
        }

        setTimeout(function() {
            contentInner
                .addToDom("inspiration-content") // id of the dom element
                .show();

        }, 400);

    },


    init: function(ScrollApp) {

        FilterProducts.getFilters();

        FilterProducts.setUpLoadMore();

        FilterProducts.external.ScrollApp = ScrollApp;


        $("#filter-con").on("click", ".inspiration-link", function(e) {

            e.preventDefault();

            FilterProducts.openPopup(this.getAttribute("href").substring(1));
        });

        $("#popup-inspiration").on("click", "#close-popup-inspiration", function(e) {

            e.preventDefault();

            FilterProducts.config.$body.removeClass("inspiration-active");
        });

        FilterProducts.config.$next.on("click", function(e) {

            e.preventDefault();

            FilterProducts.openPopup(this.getAttribute("href").substring(1));
        });

        FilterProducts.config.$prev.on("click", function(e) {

            e.preventDefault();

            FilterProducts.openPopup(this.getAttribute("href").substring(1));
        });



    }
}

module.exports = FilterProducts;
