/*
 * find the dealers
 */

var Fragments = require('../modules/fragments.js');

var _data = [
'Alberta',
'British Columbia',
'Manitoba',
'New Brunswick',
'Newfoundland and Labrador',
'Northwest Territories',
'Nova Scotia',
'Nunavut',
'Ontario',
'Prince Edward Island',
'Quebec',
'Saskatchewan',
'Yukon',
'Alabama',
'Alaska',
'Arizona',
'Arkansas',
'California',
'Colorado',
'Connecticut',
'Delaware',
'Florida',
'Georgia',
'Hawaii',
'Idaho',
'Illinois',
'Indiana',
'Iowa',
'Kansas',
'Kentucky',
'Louisiana',
'Maine',
'Maryland',
'Massachusetts',
'Michigan',
'Minnesota',
'Mississippi',
'Missouri',
'Montana',
'Nebraska',
'Nevada',
'New Hampshire',
'New Jersey',
'New Mexico',
'New York',
'North Carolina',
'North Dakota',
'Ohio',
'Oklahoma',
'Oregon',
'Pennsylvania',
'Rhode Island',
'South Carolina',
'South Dakota',
'Tennessee',
'Texas',
'Utah',
'Vermont',
'Virginia',
'Washington',
'West Virginia',
'Wisconsin',
'Wyoming'
];

var _inital = [
{ 'i' : 'AB', 'name' : 'Alberta' },
{ 'i' : 'BC', 'name' : 'British Columbia' },
{ 'i' : 'MB', 'name' : 'Manitoba' },
{ 'i' : 'NB', 'name' : 'New Brunswick' },
{ 'i' : 'NL', 'name' : 'Newfoundland and Labrador' },
{ 'i' : 'NS', 'name' : 'Nova Scotia' },
{ 'i' : 'NT', 'name' : 'Northwest Territories' },
{ 'i' : 'NU', 'name' : 'Nunavut' },
{ 'i' : 'ON', 'name' : 'Ontario' },
{ 'i' : 'PE', 'name' : 'Prince Edward Island' },
{ 'i' : 'QC', 'name' : 'Quebec' },
{ 'i' : 'SK', 'name' : 'Saskatchewan' },
{ 'i' : 'YT', 'name' : 'Yukon' },
{ "name": "Alabama", "i": "AL"},
{ "name": "Alaska", "i": "AK"},
{ "name": "Arizona", "i": "AZ"},
{ "name": "Arkansas", "i": "AR"},
{ "name": "California", "i": "CA"},
{ "name": "Colorado", "i": "CO"},
{ "name": "Connecticut", "i": "CT"},
{ "name": "Delaware", "i": "DE"},
{ "name": "District Of Columbia", "i": "DC"},
{ "name": "Florida", "i": "FL"},
{ "name": "Georgia", "i": "GA"},
{ "name": "Hawaii", "i": "HI"},
{ "name": "Idaho", "i": "ID"},
{ "name": "Illinois", "i": "IL"},
{ "name": "Indiana", "i": "IN"},
{ "name": "Iowa", "i": "IA"},
{ "name": "Kansas", "i": "KS"},
{ "name": "Kentucky", "i": "KY"},
{ "name": "Louisiana", "i": "LA"},
{ "name": "Maine", "i": "ME"},
{ "name": "Maryland", "i": "MD"},
{ "name": "Massachusetts", "i": "MA"},
{ "name": "Michigan", "i": "MI"},
{ "name": "Minnesota", "i": "MN"},
{ "name": "Mississippi", "i": "MS"},
{ "name": "Missouri", "i": "MO"},
{ "name": "Montana", "i": "MT"},
{ "name": "Nebraska", "i": "NE"},
{ "name": "Nevada", "i": "NV"},
{ "name": "New Brunswick", "i": "NB"},
{ "name": "New Hampshire", "i": "NH"},
{ "name": "New Jersey", "i": "NJ"},
{ "name": "New Mexico", "i": "NM"},
{ "name": "New York", "i": "NY"},
{ "name": "North Carolina", "i": "NC"},
{ "name": "North Dakota", "i": "ND"},
{ "name": "Ohio", "i": "OH"},
{ "name": "Oklahoma", "i": "OK"},
{ "name": "Oregon", "i": "OR"},
{ "name": "Pennsylvania", "i": "PA"},
{ "name": "Rhode Island", "i": "RI"},
{ "name": "South Carolina", "i": "SC"},
{ "name": "South Dakota", "i": "SD"},
{ "name": "Tennessee", "i": "TN"},
{ "name": "Texas", "i": "TX"},
{ "name": "Utah", "i": "UT"},
{ "name": "Vermont", "i": "VT"},
{ "name": "Virginia", "i": "VA"},
{ "name": "Washington", "i": "WA"},
{ "name": "West Virginia", "i": "WV"},
{ "name": "Wisconsin", "i": "WI"},
{ "name": "Wyoming","i": "WY" }
];

Array.prototype.unique = function() {
    var a = this.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};


var RepAutocomplete = {


    init: function(input, results) {

        var openTabFromSearch = function(e) {

            e.preventDefault();

            document.getElementById(input).value = e.target.getAttribute("href").replace("#", "");

            document.getElementById(results).innerHTML = "";
        }


        var addSearchLocations = function(_arr) {

            var list = Fragments.CreateFragment();

            document.getElementById(results).innerHTML = "";

            var i = 0;

            _arr.map(function(el) {

                if (i++ < 12) {

                   list.addLink("#" + el, el, "js-add-value add-value", "click", openTabFromSearch);
                }
            });

            list.addToDom(results).show();
        };


        document.getElementById(input).addEventListener('keyup', function(e) {

            var _arr = [];
            var _arr2 = [];

            if(this.value.length > 1) {

                _arr = _data.filter(function(el) {

                    if (el.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1) {
                        return true;
                    }
                });

                _arr2 = _inital.filter(function(el) {

                    if (el.i.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1) {
                        return true;
                    }
                }).map(function(obj) { return obj.name; });

            }
            addSearchLocations(_arr.concat(_arr2).unique());

		});

		var listen = function() {
			var buttons = document.getElementsByClassName('js-find-rep-button');

			for(var i = 0; i < buttons.length; i++) {
				(function(idx) {
					buttons[idx].addEventListener('click', function() { submit_rep(this) }, false);
				})(i);
			}
		}

		var submit_rep = function(e) {
			var elm = document.getElementById(input);
			if(elm.value !== '') {
				e.classList.add('active');
				e.parentNode.parentNode.submit();
			}
		}

		listen();
    }
}

module.exports = RepAutocomplete;
