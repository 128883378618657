/*
 * Module for filtering of products and professional tools
 */

var Fragments = require('../modules/fragments.js');


var FilterProducts = {

    config: {
        filter : ["style[]", "loc[]", "ventLoc[]", "cate[]", "config[]", "size[]", "glass[]", "vent[]"],
        filtered : { "style" : [], "loc" : [],  "ventLoc" : [], "cate" : [],  "config" : [], "size" : [], "glass" : [], "vent" : [] },
        products : json_products,
        subset : json_products,
        subsetAppend : null,
        page: 1,
        paging: 6,
        initStart: 0,
        initAmount: 5,
        itemWidth: "one-half",
        $load : $("#load")
    },

    getFilters: function() {


        $("#reset-filter").on("click", function(e) {
            e.preventDefault();

            $('[type="checkbox"]').prop('checked', false);

            FilterProducts.config.page = 1;
            FilterProducts.config.$load.removeClass("element-hidden");

            Object.keys(FilterProducts.config.filtered).forEach(function(el) {

               FilterProducts.config.filtered[el] = [];
            });

            FilterProducts.getSubset();

             $('body, html').animate({ scrollTop: $(".grid--products").offset().top }, 300);

        });

        $("#form--filter").on("submit", function(e) {

            e.preventDefault();

            FilterProducts.config.page = 1;
            FilterProducts.config.$load.removeClass("element-hidden");

            Object.keys(FilterProducts.config.filtered).forEach(function(el) {

               FilterProducts.config.filtered[el] = [];
            });

            var result = $(this).serializeArray();

            // loop through the checkboxes and add the values to the filtered var
            result.map(function(el) {

                if(FilterProducts.config.filter.indexOf(el.name) > -1 ) {

                    FilterProducts.config.filtered[el.name.substring(0,el.name.length-2)].push(el.value);
                }
            });

            FilterProducts.getSubset();

             $('body, html').animate({ scrollTop: $(".grid--products").offset().top }, 300);
        });

    },

    getSubset : function () {

        FilterProducts.config.subset = FilterProducts.config.products.filter(function(el) {

            return Object.keys(FilterProducts.config.filtered).every(function(name) {

                // if the filter is set
                if (FilterProducts.config.filtered[name].length) {

                    var inCategory = false;

                    if (!el[name]) return false;
                    // loop the categories of the products
                    el[name].forEach(function(productValue) {

                        // check if the categories are matching with the checked ones
                        if (FilterProducts.config.filtered[name].indexOf(productValue) > -1) {
                            inCategory = true;
                        }
                    });

                    if (!inCategory) return false;
                }

                return true;
            });

        });


        var start = FilterProducts.config.initStart,
            amount   = FilterProducts.config.initAmount;

        if (start+amount >= FilterProducts.config.subset.length) {

            amount = FilterProducts.config.subset.length - start;
            FilterProducts.config.$load.addClass("element-hidden");
        }

        if (FilterProducts.config.subset.length == 0) {

            document.getElementById("filter-con").innerHTML =
                "<div class='grid__item "+ FilterProducts.config.itemWidth +"'><strong>Looks like you want something custom, contact a rep near you. Or try searching again with fewer choices.</strong></div>";
            return;
        }

        FilterProducts.getPaginationSubsetAndAddToDOM(start, amount, true);
    },

    setUpLoadMore : function() {

        FilterProducts.config.$load.on("click", function(e) {

            e.preventDefault();

            var start = FilterProducts.config.initStart,
                amount   = FilterProducts.config.initAmount;

            if (FilterProducts.config.page == 1) {

                start = FilterProducts.config.initAmount;
                amount   = FilterProducts.config.paging;
            }
            if (FilterProducts.config.page > 1) {

                start = FilterProducts.config.initAmount + ((FilterProducts.config.page-1) * FilterProducts.config.paging);
                amount   = FilterProducts.config.paging;
            }

            if (start+amount >= FilterProducts.config.subset.length) {

                amount = FilterProducts.config.subset.length - start;
                this.classList.add("element-hidden");
            }

            FilterProducts.config.page++

            FilterProducts.getPaginationSubsetAndAddToDOM(start, amount, false);
        });
    },

    getPaginationSubsetAndAddToDOM : function(start, amount, init) {

        FilterProducts.config.subsetAppend = FilterProducts.config.subset.filter(function(el, id) {

            if (id >= start && id < start+amount) return true;
        });

        FilterProducts.addToDom(init);
    },

    addToDom : function(init) {

        var allElements = Fragments.CreateFragment();

        FilterProducts.config.subsetAppend.map(function(obj) {

            var item = Fragments.CreateFragment();
            var gridItemInner = Fragments.CreateFragment();
            var productLinkInner = Fragments.CreateFragment();
            var productImageBlock = Fragments.CreateFragment();

            productImageBlock
                .addImage(obj.img)
                .addTag("span", '<i class="icon-fire"></i> View Fireplace', "view");

            productLinkInner
                .appendFragment("span", productImageBlock, "image-hover")
                .addTag("h3", obj.series)
                .addTag("h4", obj.name);

            gridItemInner
               .addLink(obj.link, productLinkInner, "product-link");

            if (obj.specs) {

                gridItemInner.addLink(obj.specs, '<i class="icon-download"></i> Download specs', "link",'','','_blank');
            }

            item.appendFragment("div", gridItemInner, "grid__item js-hide filtered-element " + FilterProducts.config.itemWidth);

            allElements.appendFragment(null, item);

        });


        if (init) { document.getElementById("filter-con").innerHTML = ""; }

        // do only one repaint/adding to dom
        allElements
            .addToDom("filter-con") // id of the dom element
            .show(); // can be used to add loading transition

        setTimeout(function() { $(".js-hide").removeClass("js-hide"); }, 800);


        // <div class="grid__item one-half filtered-element">  ########################-> gridItemInner, it's children
        //     <a href="{{product.link}}" class="product-link">  ########################-> productLinkInner, it's children
        //         <span class="image-hover">  ########################-> productImageBlock, it's children
        //             <img src="{{product.img}}" alt="{{product.name}}" />
        //             <span class="view">
        //                 <i class="icon-fire"></i> View Fireplace
        //             </span>
        //         </span>
        //         <h3>{{product.series|default('&nbsp;')}}</h3>
        //         <h4>{{product.name}}</h4>
        //     </a>
        //     {% if product.specs %}
        //         <a href="{{product.specs}}" class="link"><i class="icon-download"></i> Download specs</a>
        //     {% endif %}
        // </div>
    },


    init: function(config) {

        FilterProducts.config =  $.extend(FilterProducts.config, config);

        FilterProducts.getFilters();

        FilterProducts.setUpLoadMore();


    }
}

module.exports = FilterProducts;
