/*
 * Parallax effect
 */

var Parallax = function() {
        
    var self = this;

    this.init = function(_vars, reset) {

        // reset on page load or when resized
        if (reset) { self.items = document.querySelectorAll(".js-para"); }

        self.execute(_vars);
    };

    this.execute = function(_vars) {
        NodeList.prototype.forEach = Array.prototype.forEach;

        var moveMax = 100;

        if (self.items) {

            self.items.forEach(function(el) {

                el._topOffset = el.getBoundingClientRect().top;

                el._moveHeight = el.getBoundingClientRect().height + _vars.windowHeight;
                el._elHeight = el.getBoundingClientRect().height;
                

                el._ratio = 1 - ( (el._moveHeight - (el._topOffset + el._elHeight))  / el._moveHeight);

                el._y1 = (el._ratio * moveMax) - (moveMax/2);

                if (el._ratio > 0 && el._ratio < 1) { 

                    el.setAttribute('style', '-ms-transform:translateY('+el._y1+'px); ' +
                            '-webkit-transform:translateY('+el._y1+'px); transform:translateY('+el._y1+'px)');
                }   

                if (el._ratio < 0) {

                    el.setAttribute('style', '-ms-transform:translateY(-'+(moveMax/2)+'px); ' +
                            '-webkit-transform:translateY(-'+(moveMax/2)+'px); transform:translateY(-'+(moveMax/2)+'px)');
                }

                if (el._ratio > 1) {

                    el.setAttribute('style', '-ms-transform:translateY('+(moveMax/2)+'px); ' +
                            '-webkit-transform:translateY('+(moveMax/2)+'px); transform:translateY('+(moveMax/2)+'px)');
                }

            });
        }
    };
};

module.exports = Parallax;
