var imagesLoaded = require('imagesloaded');

var ImagesLoaded = {

    init: function(className) {

        $(className).each(function() {

            var self = this;

            $(this).imagesLoaded({background: true}, function(className) {

                $(self).addClass('is-loaded');
            });
        })
    }
}

module.exports = ImagesLoaded;
