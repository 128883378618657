/*
* CTA Popup : Custom code
**/

var Popups = {


    init: function(MobileNav) {

        var $linkOpen = $(".site-nav__cta");
        var $linkClose = $("#close-popup");
        var $linkBack = $("#back-popup");
        var $body = $("body");

        // if ($body.hasClass("page-id-316")) {

        //     $linkOpen.on("click", function(e) {
        //         e.preventDefault();
        //         $('body, html').animate({ scrollTop: $("h1").offset().top - 20 }, 300);
        //     });
        // }
        // else {

            $linkClose.on("click", function(e) {
                e.preventDefault();

                $body.removeClass("cta-active");
            });

            $linkOpen.on("click", function(e) {
                e.preventDefault();

                $body.addClass("cta-active");
            });
        //}


        $linkBack.on("click", function(e) {
            e.preventDefault();

            $(".js-swap").addClass("js-inactive");
            $(".js-swap").removeClass("js-in");

            $(".js-0").removeClass("js-inactive");

            setTimeout(function(e) { $(".js-0").addClass("js-in"); }, 100);

            $(this).addClass("element-hidden");
        });

        $("#mobile-cta").on("click", function(e) {
            e.preventDefault();

            $body.removeClass("cta-active");

            MobileNav.close();
        });



        $(".swap-form").on("click", function(e) {
            e.preventDefault();

            var _id = this.getAttribute("data-id");

            $(".js-swap").addClass("js-inactive");
            $(".js-swap").removeClass("js-in");

            $(".js-"+_id).removeClass("js-inactive");

            $(".js-"+_id).find("[type=text]").focus();

            setTimeout(function(e) { $(".js-"+_id).addClass("js-in"); }, 100);

            $linkBack.removeClass("element-hidden");

        });



        // $(".validate").on("submit", function(e) {

        //     if ($(this).find("[name='postal']").val().length < 5 || $(this).find("[name='postal']").val().length > 7) {

		// 		$(this).find(".error").removeClass("element-hidden");
        //         return false;
        //     }
        //     else {
		// 		$(this).find(".error").addClass("element-hidden");
        //     }

		// });

		$(".js-find-dealer-button").on("click", function() {
			var validate = $(this).parent().parent('.validate');
			if (validate.find("[name='postal']").val().length < 5 || validate.find("[name='postal']").val().length > 7) {
				validate.find(".error").removeClass("element-hidden");
				return false;
			} else {
				validate.find(".error").addClass("element-hidden");
				$(this).addClass("active");
				validate.submit();
			}
		});

    }
}

module.exports = Popups;
