/*
 * Parallax effect
 */

var RotateCircle = function() {

    var item, parent;

    this.init = function(_vars, reset) {

        // reset on page load or when resized
        if (reset) {

            item = document.querySelector(".circle-text");
            parent = document.querySelector("#rotation");
        }

        this.execute(_vars);
    };

    this.execute = function(_vars) {
        NodeList.prototype.forEach = Array.prototype.forEach;



            if (parent.getBoundingClientRect().top - _vars.windowHeight - 300 <= 0) {

                var ratio = (parent.getBoundingClientRect().top / _vars.windowHeight) * 100;

                // console.log(ratio);

                item.setAttribute('style', 'transform:translateX(-90px) translateY(-50%) rotate('+ ratio +'deg)');

            }
    };
};

module.exports = RotateCircle;
