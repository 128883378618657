/*

var tab = Fragments.CreateFragment();
var tabContent = Fragments.CreateFragment();
var h5Content = Fragments.CreateFragment();

h5Content
    .addLink("#", "that's a link<br/>")
    .addTag("span", "that's a span", "span--block");

tabContent
    .addTag("h3", "some title")
    .addTag("p", "some text", "some-class")
    .appendFragment("h5", h5Content)
    .addList("ul", ["first item", "second item"], "classname")

if (true) { 

    tabContent.addLink("xxxxxx.html", "<span>view details</span>", "btn btn--gradient"); 
}

tab
    .addImage("image_url.jpg")
    .addLink("#", "<i class='icon-times'></i>", "js-close-tab", "click", closeTab)
    .appendFragment("div", tabContent, "inner") // with wrapper tag
    .appendFragment(null, tabContent) // without wrapper tag
    .addToDom("project-tab") // id of the dom element
    .show(); // can be used to add loading transition
*/


var Fragments = {

    CreateFragment : function() {
        
        var docFragment = document.createDocumentFragment(),
            _dom = false,
            _el = null;
       
        var addTag = function(tag, inner, className) {

            _el = document.createElement(tag);

            _el.innerHTML = inner;

            _el = _addClass(_el, className);

            docFragment.appendChild(_el);

            return this;
        };

        var addImage = function(src, className, srcset) {

            _el = document.createElement("img");
            _el.src = src;

            if (srcset) {
                _el.setAttribute("srcset", srcset);
            }

            _el = _addClass(_el, className);

            docFragment.appendChild(_el);

            return this;
        };


        var addLink = function(href, text, className, evt, eventFn, target, wrapper) {

            _el = document.createElement("a");
            _el.href = href;

            if (typeof text === "object") {

                _el.appendChild(text.docFragment);
            }
            else {
                _el.innerHTML = text;
            }

            _el = _addClass(_el, className);

            if (evt && typeof eventFn === "function") {

                _el.addEventListener(evt, eventFn);
            }

            if (target) { _el.setAttribute("target", target); }

            if (wrapper) {
                var _wrapper = document.createElement(wrapper);
                _wrapper.appendChild(_el);
                docFragment.appendChild(_wrapper);
            }
            else {
                docFragment.appendChild(_el);
            }

            return this;
        };


        var addToDom = function(id, replaceFormer) {

            _dom = document.getElementById(id);

            if (replaceFormer) { _dom.innerHTML = ""; }
            _dom.appendChild(docFragment);

            return this;
        };



        var show = function() {

            if (_dom) { _dom.classList.add("js-in"); }
        };
        var hide = function() {

            if (_dom) { _dom.classList.remove("js-in"); }
        };


        var appendFragment = function(tag, fragment, className, attrArr) {

            if (tag) {
                _el = document.createElement(tag);
            
                _el = _addClass(_el, className);

                if (attrArr) { _el.setAttribute(attrArr[0], attrArr[1]); }

                _el.appendChild(fragment.docFragment);

                docFragment.appendChild(_el);
            }
            else {
                docFragment.appendChild(fragment.docFragment);
            }
            return this;
        };


        var _addClass = function(_el, className) {

            if (!className) return _el;

            if (className.indexOf(" ") > -1) {

                className.split(" ").map(function(cln) {  _el.classList.add(cln); });
            }
            else {
                _el.classList.add(className);
            }
            return _el;
        };


        return {
            docFragment : docFragment,
            addTag : addTag,
            addImage : addImage,
            addLink : addLink,
            addToDom : addToDom,
            appendFragment : appendFragment,
            show : show,
            hide : hide
        };  
    }   
}


module.exports = Fragments;
