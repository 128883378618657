/*
 * Module to fade in elements while scrolling
 */

var FadeInBlocks = function() {
        
    var self = this;

    this.init = function(_vars, reset) {

        // reset on page load or when resized
        if (reset) { self.items = document.querySelectorAll(".js-fade"); }

        self.execute(_vars);
    };

    this.execute = function(_vars) {

        NodeList.prototype.forEach = Array.prototype.forEach;

        if (self.items) {

            self.items.forEach(function(el) {

                var topOffset = el.getBoundingClientRect().top;

                var _from = (_vars.windowHeight/4)*3;

                if (el.getAttribute("data-from")) {
                    _from = _vars.windowHeight*Number(el.getAttribute("data-from"));
                }

                if (_from > topOffset || 
                        _vars.scrollTop < 50 && (_vars.windowHeight*0.99 > topOffset)) {

                  el.classList.add("js-in");
                }   
            });
        }
    };
};

module.exports = FadeInBlocks;
