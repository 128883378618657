/*
 * Dealers locator
 */

var CalcDistance = require('../modules/calcDistance.js');
var ejs = require('ejs');

var GetDealers = {

    cf : {
        $postalInput: $('.js-dealers-search-input'),
        $postalForm: $('#dealers-search'),
        postalCode: '',
        dealers: json_dealers,
        geocoder: new google.maps.Geocoder(),
        seriesFilterNumber: 3, // used for sorting logic, amount defined as more desirable to a user (more than 3 series = show up first)
        geocodePayload: null,
        platinumDealers: [],
        goldDealers: [],
        silverDealers: [],
        $map: $('.js-dealer-map'),
        map: null,
        markers: [],
        bounds: null,
        isAmerican: false,
        radius: 25,
        activeRadiusFilter: 25,
        activeFilters: [],
        sortedDealers: [],
    },

    // purely for accessibility, so user can't tab outside popup when it's active (especially for mobile)
    trapFocus: function(element, namespace) {

        var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'),
            firstFocusableEl = focusableEls[0];
            lastFocusableEl = focusableEls[focusableEls.length - 1];
            KEYCODE_TAB = 9;

        element.addEventListener('keydown', function(e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if ( e.shiftKey ) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }

        });
    },

    geocode: function(loc) {
        return new Promise(function(resolve, reject) {
            GetDealers.cf.geocoder.geocode( loc, function(results, status) {

                if (status == google.maps.GeocoderStatus.OK) {

                    resolve(results[0]);

                } else {
                    GetDealers.errorMessage('Sorry, geocoding was not successful. Please try a different postal code.');
                    reject("Geocode was not successful for the following reason: " + status);
                }
            });
        });
    },

    sortDealers: function(postalCode) {

        // add loading animation
        GetDealers.loadingAnim.add();

        var initSort = function(){

            // reset status dealers before we begin
            GetDealers.cf.platinumDealers = [];
            GetDealers.cf.goldDealers = [];

            /*
             * first, filter all dealers and add 'distance' key for distance between postal code and dealer location.
             * if it's further away than 25 [or whatever distance is selected for radius] throw it out
             * we also want to remove any dealers with a dealer status platinum, gold, or silver
             * (we'll be dealing with these separately first)
             */

            var lat = GetDealers.cf.geocodePayload.geometry.location.lat(),
                lng = GetDealers.cf.geocodePayload.geometry.location.lng();

            var trimmedDealers = GetDealers.cf.dealers.filter(function(dealer){

                // add this data to each dealer for futher filtering and sorting
                dealer.distance      = CalcDistance.between2Points(dealer.loc[0], dealer.loc[1], lat, lng).toFixed(2);
                dealer.distanceMiles = GetDealers.kmToMiles(dealer.distance).toFixed(2);

                var distanceCheck = dealer.distance <= GetDealers.cf.radius,
                    noStatusCheck = dealer.dealer_status == null || !dealer.dealer_status.length,
                    filtersCheck   = true;

                // do a filter check too if there's active filters
                if (GetDealers.cf.activeFilters.length) {

                    // first, change filtersCheck to false so it won't appear if it doesn't have any matching attributes
                    filtersCheck = false;

                    if (dealer.dealer_attributes !== null) {
                        
                        dealer.dealer_attributes.forEach(function(attr){
                            
                            filtersCheck = GetDealers.cf.activeFilters.includes(attr) ? true : filtersCheck;
                        });
                    }   
                }
                

                // let's kick all dealers WITH status type into new arrays for later (if less thank 25mi away, this is a distance requested by the client and does not have anything to do with the radius filter), but still keep them in the original array
                var statusRadius = 25;
                if (!noStatusCheck) {
                    if (dealer.dealer_status == 'platinum: Platinum' || dealer.dealer_status == 'platinum') {

                        if (dealer.distanceMiles < statusRadius && filtersCheck) {
                            GetDealers.cf.platinumDealers.push(dealer);
                            // make sure it's not added to the main array
                            return false;
                        }
                        

                    } else if (dealer.dealer_status == 'gold' && filtersCheck) {
                        if (dealer.distanceMiles < statusRadius) {
                            GetDealers.cf.goldDealers.push(dealer);
                            return false;
                        }
                    }
                }
                
                return distanceCheck && filtersCheck;
            });

            /*
             * Next, we need to ensure we show first all platinum dealers within 15 miles,
             * followed by all gold dealers within 15 miles. After that, show the rest of the results
             */

            if (GetDealers.cf.platinumDealers.length) {                
                // sort by distance
                GetDealers.cf.platinumDealers.sort(function(a, b) {
                    if (Number(a.distance) > Number(b.distance)) return 1;
                    else return -1;
                });
            };

            if (GetDealers.cf.goldDealers.length) {
                GetDealers.cf.goldDealers.sort(function(a, b) {
                    if (Number(a.distance) > Number(b.distance)) return 1;
                    else return -1;
                });
            }

            // sort the rest by distance
            var sortedRemaining = trimmedDealers.sort(function(a, b) {
                if (Number(a.distance) > Number(b.distance)) return 1;
                else return -1;
            });

            // slice dealer length down to 25. client requested that we NEVER show more than 25 locations at a time.
            var slicedDealers = sortedRemaining.slice(0, 25 - GetDealers.cf.goldDealers.length - GetDealers.cf.platinumDealers.length);

            // now put them all together
            var sortedDealers = GetDealers.cf.platinumDealers.concat(GetDealers.cf.goldDealers, slicedDealers);

            GetDealers.cf.sortedDealers = sortedDealers;

            GetDealers.mapDealers(GetDealers.cf.sortedDealers);
            GetDealers.listDealers(GetDealers.cf.sortedDealers);
        };

        /*
         * now, run function above this. If there's a new postal code, geocode it
         * otherwise it's just a change of filters so proceed with current postal code
         */
        if (postalCode && postalCode !== GetDealers.cf.postalCode) {
            
            GetDealers.cf.postalCode = postalCode;

            // get lat lng of postal code, then start sorting
            GetDealers.geocode({'address': postalCode}).then(function(payload) {

                GetDealers.cf.geocodePayload = payload;

                var isAmericanCheck = false;

                // need to determine if the distances should be primarily km or miles
                payload.address_components.forEach(function(i){
                    if (i.long_name == 'United States') {
                        isAmericanCheck = true;
                    }
                });

                /*
                 * only update the radius if it hasn't already been calculated in miles
                 * otherwise it will increase with each search
                 */

                if (GetDealers.cf.isAmerican !== isAmericanCheck) {

                    GetDealers.cf.isAmerican = isAmericanCheck;
                    // calculate in miles if searched location is american
                    GetDealers.cf.radius = GetDealers.cf.isAmerican ? GetDealers.milesToKm(GetDealers.cf.radius) : GetDealers.cf.activeRadiusFilter;
                }
                
                initSort();

            }, function(error) {

                // postal code geolocation failed
                console.log(error);
            });

        } else {
            initSort();
        }

        GetDealers.loadingAnim.remove();
    },

    kmToMiles: function(kms){
        return kms*0.6214;
    },
    milesToKm: function(km){
        return km*1.60934;
    },

    listDealers: function(dealers){

        if (GetDealers.cf.isAmerican) {
            // change the filters to km or miles
            $('.js-dealer-radius').addClass('is-american');
        } else {
            $('.js-dealer-radius').removeClass('is-american');
        }

        if (dealers.length) {
            var html = ejs.render(
                '<% dealers.forEach(function(dealer, i){ %>' +

                    '<% var cardClass = dealer.dealer_status == "platinum: Platinum" || dealer.dealer_status == "platinum" ? "dealer-card--platinum" : "" %>'+ 

                    '<a href="#" class="dealer-card <%= cardClass %> js-dealer-card" data-index="<%= i %>">'+

                        '<% if (dealer.dealer_status == "platinum: Platinum") { %>'+
                            '<span class="dealer-card__status dealer-card__status-platinum">Platinum Dealer</span>'+
                        '<% } else if (dealer.dealer_status !== null) { %>'+
                            '<% if (dealer.dealer_status.length) { %>'+
                                '<span class="dealer-card__status dealer-card__status-<%= dealer.dealer_status %>"><%= dealer.dealer_status %> Dealer</span>'+
                            '<% } %>'+
                        '<% } %>'+

                        '<h2 class="dealer-card__title">'+
                            '<%= dealer.name %> '+
                            
                        '</h2>'+
                        '<span class="dealer-card__distance">'+
                            '<% if (isAmerican) { %>'+
                                '<%= dealer.distanceMiles %>MI / <%= dealer.distance %>km Away '+
                            '<% } else { %>'+
                                '<%= dealer.distance %>km / <%= dealer.distanceMiles %> MI Away'+
                            '<% } %>'+
                        '</span>'+

                        '<% if (dealer.non_displaying_dealer !== null && dealer.non_displaying_dealer) { %>'+
                            '<span class="dealer-card__series">Non-displaying dealer</span>'+
                        '<% } else if (dealer.fireplace_series !== null) { %>'+
                            '<% if (dealer.fireplace_series.length) { %>'+
                                '<span class="dealer-card__series"><%= dealer.fireplace_series.length %> series on display</span>'+
                                '<span class="dealer-card__series-link">See available series</span>'+
                            '<% } %>'+
                        '<% } %>'+
                        '<div class="dealer-card__bottom">'+
                            '<div class="dealer-card__attributes">'+
                                '<% if (dealer.dealer_attributes !== null) { %>'+
                                    '<% for (var i = 0; i < dealer.dealer_attributes.length; i++) { %>'+
                                        '<% var title = dealer.dealer_attributes[i] == "centre" ? "SERVICE " : "" %>'+
                                        '<span title="<%= title %><%= dealer.dealer_attributes[i].toUpperCase() %>" class="dealer-icon dealer-icon--<%= dealer.dealer_attributes[i] %>">'+
                                            '<% if (dealer.dealer_attributes[i] == "residential" || dealer.dealer_attributes[i] == "commercial" || dealer.dealer_attributes[i] == "outdoor") { %>'+
                                                '<span class="sr-only">This dealer has <%= dealer.dealer_attributes[i] %> fireplaces</span>'+
                                            '<% } else if (dealer.dealer_attributes[i] == "centre") { %>'+
                                            '<span class="sr-only">This dealer has a service centre</span>'+
                                            '<% } else { %>' +
                                            '<span class="sr-only">This dealer offers <%= dealer.dealer_attributes[i] %></span>'+
                                            '<% } %>'+
                                        '</span>'+
                                    '<% } %>'+
                                '<% } %>'+
                            '</div>'+
                            '<div class="dealer-card__more">More Details<span class="sr-only"> about this dealer</span></div>'+
                        '</div>'+
                    '</a>'+
                '<% }) %>',
                {
                    dealers: dealers,
                    isAmerican: GetDealers.cf.isAmerican,
                }
            );

            // add to page
            $('.js-dealers-cards').html(html);

        } else {
            GetDealers.errorMessage('No results, please increase your search radius or remove filters.');
        }
    },

    errorMessage: function(message){
        var html = ejs.render(message);
        $('.js-dealers-cards').html('<h4>' + html + '</h4>');
    },

    mapDealers: function(dealers){

        // first remove all markers from the map
        GetDealers.cf.markers.forEach(function(marker){
            marker.setMap(null);
        });

        GetDealers.cf.bounds = new google.maps.LatLngBounds();

        // gotta set markers as their own array so it's easier to remove them from the map when location changes
        GetDealers.cf.markers = dealers.map(function(dealer, i){

            var latLng = {
                lat: parseFloat(dealer.loc[0]),
                lng: parseFloat(dealer.loc[1])
            };

            if (dealer.dealer_status !== null && dealer.dealer_status.length) {
                // theme link var comes from twig file
                var markerImg = theme_link + '/assets/dist/img/marker-platinum.png';
            } else {
                var markerImg = theme_link + '/assets/dist/img/marker-dealer.png';
            }

            var markerIcon = {
                url: markerImg,
                size: new google.maps.Size(46, 58),
                scaledSize: new google.maps.Size(23, 29),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 26)
            };

            var marker = new google.maps.Marker({
                position: latLng,
                map: GetDealers.cf.map,
                icon: markerIcon,
            });

            marker.addListener('click', function() {
                GetDealers.showDealerInfo(i);
            });

            // add marker to bounds
            GetDealers.cf.bounds.extend(latLng);

            return marker;
        });

        if (!GetDealers.cf.markers.length) {

            // draw a circle on the map of the radius incase there's no results
            var circle = new google.maps.Circle({
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                // map: GetDealers.cf.map, // don't actually add circle to map just use for calculating bounds
                center: {
                    lat: GetDealers.cf.geocodePayload.geometry.location.lat(),
                    lng: GetDealers.cf.geocodePayload.geometry.location.lng(),
                },
                radius: GetDealers.cf.radius * 1000,
            });

            GetDealers.cf.bounds.union(circle.getBounds());

        } else {

            // also make sure bounds include postal code if not using the circle (circle has this point as the centre)
            GetDealers.cf.bounds.extend({
                lat: GetDealers.cf.geocodePayload.geometry.location.lat(),
                lng: GetDealers.cf.geocodePayload.geometry.location.lng(),
            });
        }

        // zoom map in to markers
        GetDealers.cf.map.fitBounds(GetDealers.cf.bounds);

    },

    loadingAnim: {
        add: function(){
            $('.js-dealers-locator').addClass('is-loading');
        },
        remove: function(){
            setTimeout(function(){
                $('.js-dealers-locator').removeClass('is-loading');
            }, 400);
        }
    },

    showDealerInfo: function(index) {

        // first, remove any other active popups before adding
        $('.js-dealer-popup-wrap').html('');
        $('.js-dealer-card.is-open').removeClass('is-open');

        // add active class to card
        $('.dealer-card[data-index="'+ index +'"]').addClass('is-open');
        $('.dealer-card[data-index="'+ index +'"] .dealer-card__more').html('Fewer Details<span class="sr-only"> about this dealer</span>');

        // add class to body so it doesn't scroll in the background
        $('body').addClass('is-frozen');

        var dealer = GetDealers.cf.sortedDealers[index];
        console.log(dealer);

        var html = ejs.render(
            '<div class="dealer-popup js-dealer-popup" role="dialog" aria-modal="true">'+
                '<div tabindex="0"></div>'+
                '<div class="dealer-popup__inner">'+
                    '<button class="dealer-popup__close js-dealer-popup-close">Close</button>'+
                    '<p class="beta"><%= dealer.name %></p>'+

                    '<% if ((dealer.dealer_status !== null && dealer.dealer_status.length) || (dealer.dealer_attributes !== null && dealer.dealer_attributes.length)) { %>'+
                        '<div class="dealer-popup__meta">'+
                            '<% if (dealer.dealer_status !== null && dealer.dealer_status.length) { %>'+
                                '<span class="dealer-popup__status dealer-popup__status--<%= dealer.dealer_status %>"><%= dealer.dealer_status %> dealer</span>'+
                            '<% } %>'+

                            '<% if (dealer.dealer_attributes !== null) { %>'+
                                '<% for (var i = 0; i < dealer.dealer_attributes.length; i++) { %>'+
                                    '<% var title = dealer.dealer_attributes[i] == "centre" ? "SERVICE " : "" %>'+
                                    '<span title="<%= title %><%= dealer.dealer_attributes[i].toUpperCase() %>" class="dealer-icon dealer-icon--<%= dealer.dealer_attributes[i] %>">'+
                                        '<span class="sr-only">This dealer has <%= dealer.dealer_attributes[i] %> fireplaces</span>'+
                                    '</span>'+
                                '<% } %>'+
                            '<% } %>'+
                        '</div>'+
                    '<% } %>'+

                    '<div class="grid">'+
                        '<div class="grid__item one-third md-one-half sm-one-whole trailer--half">'+
                            '<h3>Address</h3>'+
                            '<p class="mb-01">'+
                                '<%= dealer.street %><br />'+
                                '<%= dealer.city %>, <%= dealer.state %><br />'+
                                '<%= dealer.postal %>'+
                            '</p>'+
                            '<a class="dealer-popup__directions" href="https://www.google.com/maps/dir/?api=1&origin=<%= encodeURIComponent(postalCode) %>&destination=<%= encodeURIComponent(dealer.street) %>%20<%= dealer.city %>%20<%= dealer.state %>%20<%= dealer.postal %>" target="_blank">Get Directions</a>'+
                        '</div>'+
                        '<div class="grid__item one-third md-one-half sm-one-whole trailer--half">'+
                            '<h3>Contact</h3>'+
                            '<%= dealer.phone %>'+
                            '<% if (dealer.email.length) { %>'+
                                '<br /><a class="link--accessible" href="mailto:<%= dealer.email %>"><%= dealer.email %></a>'+
                            '<% } %>'+
                            '<% if (dealer.web.length) { %>'+
                                '<br /><a class="link--accessible" href="<%= dealer.web %>" target="_blank"><%= dealer.web %></a>'+
                            '<% } %>'+
                        '</div>'+
                    '</div>'+
                    '<% if (dealer.non_displaying_dealer !== null && dealer.non_displaying_dealer) { %>'+
                        '<h3>Non-displaying dealer</h3>'+
                    '<% } else if (dealer.fireplace_series.length) { %>'+
                        '<h3>Available in showroom</h3>'+
                        '<div class="grid">'+
                            '<% dealer.fireplace_series.forEach(function(series, i){ %>'+
                                '<div class="grid__item one-quarter md-one-half xs-one-whole">'+
                                    '<div class="dealer-popup__series">'+
                                        '<img src="<%= series.series_thumbnail.sizes["series-small"] %>" alt="" />'+
                                        '<span class="entitle"><%= series.series_name %></span><br />'+
                                        '<span class="entitle--thin"><%= series.old_series_name %></span>'+
                                    '</div>'+
                                '</div>'+
                            '<% }) %>'+
                        '</div>'+
                    '<% } %>'+
                '</div>'+
                '<div tabindex="0"></div>'+
            '</div>',
            {
                dealer: dealer,
                postalCode : GetDealers.cf.$postalInput[0].value,
            }
        );

        $('.js-dealer-popup-wrap').html(html);

        $('.js-dealer-popup-close').focus();
        GetDealers.trapFocus($('.js-dealer-popup')[0]);
    },

    hideDealerInfo: function() {
        $('body').removeClass('is-frozen');
        $('.dealer-card').removeClass('is-open');
        $('.js-dealer-popup-wrap').html('');
        $('.dealer-card__more').html('More Details<span class="sr-only"> about this dealer</span>');
    },

    geolocateUser: function(){
        // Try HTML5 geolocation.
        if (navigator.geolocation) {

            GetDealers.loadingAnim.add();

            navigator.geolocation.getCurrentPosition(function(position) {

                var latLng = {lat: position.coords.latitude, lng: position.coords.longitude}

                // need to geolocate the position to determine if it's in the US or Canada (to show miles or km)
                GetDealers.geocode({'location': latLng}).then(function(payload) {

                    GetDealers.cf.geocodePayload = payload;
                    GetDealers.sortDealers();

                }, function(error) {

                    // postal code geolocation failed
                    console.log(error);
                });

            }, function() {

                GetDealers.loadingAnim.remove();
                GetDealers.errorMessage("Sorry, you may have geolocation disabled. Please search by postal code.");
            }, {
                timeout: 5000, // timeout after 5 seconds
            });

        } else {
            // Browser doesn't support Geolocation or user doesn't allow it.
            GetDealers.errorMessage("Sorry, geolocation didn't work. Please search by postal code.");
        }
    },

    bindUIActions: function() {

        // sort dealers when new postal code is entered
        GetDealers.cf.$postalForm.on('submit', function(e) {
            e.preventDefault();
            GetDealers.hideDealerInfo();
            GetDealers.sortDealers(GetDealers.cf.$postalInput[0].value);
        });

        // open dealer lightbox
        $('body').on('click', '.dealer-card', function(e){
            e.preventDefault();
            if ($(this).hasClass('is-open')) {
                GetDealers.hideDealerInfo();
            } else {
                GetDealers.showDealerInfo( $(this).attr('data-index') );
            }
        });

        // close dealer lightbox
        $('body').on('click', '.js-dealer-popup-close', function(){
            GetDealers.hideDealerInfo();
        });

        // change search radius
        $('.js-dealers-radius-btn').on('click', function(){

            var activeClass = 'is-active';
            $('.js-dealers-radius-btn').removeClass(activeClass)
            $(this).addClass(activeClass);

            var radius = parseInt($(this).attr('data-radius'));
            /*
             * need activeRadiusFilter so we don't always calculate miles radius 
             * on every submit (check if it's changed first, otherwise each submit
             * makes the radius larger)
             */
            GetDealers.cf.activeRadiusFilter = radius; 

            GetDealers.cf.radius = GetDealers.cf.isAmerican ? GetDealers.milesToKm(radius) : radius;

            // sortDealers again with last inputted postal code and new radius
            GetDealers.sortDealers();
        });

        // close modal if escape key is pressed
        $(document).keydown(function(event) {
            if (event.keyCode == 27) {
                GetDealers.hideDealerInfo();
            }
          });

        // toggle filters dropdown
        $('.js-dealer-filters-toggle').on('click', function(){
            $('.js-dealer-filters').toggleClass('is-open');
        });

        // toggle filters
        $('.js-dealer-filter-attribute').on('click', function(){
            var attribute = $(this).attr('data-dealer-attribute'),
                activeClass = 'is-active';

            // remove the filter attribute if it already exists
            if (GetDealers.cf.activeFilters.includes(attribute)) {

                var newFilters = GetDealers.cf.activeFilters.filter(function(attr){
                    return attribute !== attr;
                });

                GetDealers.cf.activeFilters = newFilters;
                $(this).removeClass(activeClass);
            } else {
                GetDealers.cf.activeFilters.push(attribute);
                $(this).addClass(activeClass);
            }

            // add active class to main button if filters are selected
            if (GetDealers.cf.activeFilters.length) {

                $('.js-dealer-filters').addClass(activeClass);
            } else {
                $('.js-dealer-filters').removeClass(activeClass);
            }

            GetDealers.sortDealers();
        });
    },
    init: function(className) {

        GetDealers.bindUIActions();

        // show map
        GetDealers.cf.map = new google.maps.Map(GetDealers.cf.$map[0], {
            center: {lat: 52.145446, lng: -100.799305}, // roughly middle of north america
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 4,
            disableDefaultUI: true
        });

        var postalCode = GetDealers.cf.$postalInput[0].value;
        if (postalCode) {
            // if postal code is already entered on page load, sort the dealers
            GetDealers.sortDealers(postalCode);
        } else {

            // if no postal code, let's try and gelocate the user
            GetDealers.geolocateUser();
        }
    }
}

module.exports = GetDealers;
