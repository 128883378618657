/*
 * Gravity Form's AJAX submission removes the listeners in the form when submitted
 * Therefore we bind a listener to the outerwrapper and delegate
 */

var formController = {

    init: function() {
        try {

            $('form, .gform_wrapper').on('focus', 'input, textarea, select', function(e) {
                $(this).closest('.form__group, .gfield').addClass('focus');
            });

            $('form, .gform_wrapper').on('blur', 'input, textarea, select', function(e) {
                $(this).closest('.form__group, .gfield').removeClass('focus');
            });
        }
        catch(e) {}

        function getURLParameter(variable) {
               var query = window.location.search.substring(1);
               var vars = query.split("&");
               for (var i=0;i<vars.length;i++) {
                       var pair = vars[i].split("=");
                       if(pair[0] == variable){return pair[1];}
               }
               return(false);
        }

        $(window).on("load", function() {

            setTimeout(function() {

                if (getURLParameter("engineer")) {

                    $("#input_1_5").val("engineer");
                }
            }, 200);


        });
        


    }
}

module.exports = formController;
