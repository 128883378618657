/*
 * App for homepage masthead scroll link
 * variables: trigger, target, (boolean: to bottom of target)
 */

var ScrollDown = {

    init: function(idTrigger, idTarget, bottom) {

        $(idTrigger).on('click', function(e) {

             e.preventDefault();

             var offset = $(idTarget).offset().top;

             if (bottom) {
                 offset += $(idTarget).outerHeight();
             }

             $('body, html').animate({
				 scrollTop: offset
			 }, 300);
        });
    }
}

module.exports = ScrollDown;
